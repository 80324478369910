// override bootstrap variables
$primary: #006B5A !default;
$secondary: #00476B !default;
$danger: #9E0019 !default;
// override bootstrap background color
$bg: #00382f !default;

@import "../node_modules/bootstrap/scss/bootstrap";

.App {
  background-color: $bg;

  .tab-pane.show {
    // center content in container
    justify-content: center;

    // grid setup
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
    column-gap: .5rem;
    row-gap: .5rem;

    // increase vertical spacing on larger screens
    @include media-breakpoint-up(sm) {
      row-gap: 1rem;
    }
  }
}
