.service-wrapper {
  // ensure cards are the same height
  &, .card {
    height: 100%;
  }

  .card {
    // hover effects
    transition: .2s;

    &:hover {
      opacity: .75;
    }
  }
}
