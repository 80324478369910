.card {
  img {
    // ensure image doesn't get too big
    height: 125px;
    // keep aspect ratio when resizing
    object-fit: contain;
  }

  button {
    .service-btn-icon {
      // ensure icon and spinner are the same width
      width: 25px;

      .spinner-grow {
        // override vertical alignment of spinner
        --bs-spinner-vertical-align: -0.0625em;
      }
    }
  }
}

